var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DimensionsSelectedStyled', [_c('HeaderStyled', [_vm._v(" " + _vm._s(_vm.$t('titles.configureDimensions')) + " ")]), _c('MainStyled', [_c('draggable', _vm._b({
    staticClass: "list-group",
    attrs: {
      "list": _vm.widgetDimensions,
      "disabled": !_vm.enabled,
      "ghost-class": "ghost",
      "handle": ".handle"
    },
    on: {
      "start": function start($event) {
        _vm.dragging = true;
      },
      "end": function end($event) {
        _vm.dragging = false;
      }
    }
  }, 'draggable', _vm.dragOptions, false), [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": !_vm.dragging ? 'flip-list' : null
    }
  }, _vm._l(_vm.dimensionsMapped, function (dimension, index) {
    return _c('AssetDimension', {
      key: dimension.id,
      attrs: {
        "id": dimension.id,
        "dimension": dimension,
        "index": index,
        "selectedWidget": _vm.selectedWidget
      },
      on: {
        "removeDimension": function removeDimension($event) {
          return _vm.$emit('removeDimension', dimension);
        },
        "toggleTargetValueEnabled": function toggleTargetValueEnabled($event) {
          return _vm.$emit('toggleTargetValueEnabled', dimension);
        },
        "updateTargetValue": function updateTargetValue($event) {
          return _vm.$emit('updateTargetValue', dimension, $event);
        },
        "updateChartType": function updateChartType($event) {
          return _vm.$emit('updateChartType', dimension, $event);
        }
      }
    });
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }