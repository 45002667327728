<template>
  <DimensionsSelectedStyled>
    <HeaderStyled>
      {{ $t('titles.configureDimensions') }}
    </HeaderStyled>
    <MainStyled>
      <draggable
        :list="widgetDimensions"
        :disabled="!enabled"
        class="list-group"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
        handle=".handle"
        v-bind="dragOptions"
      >
        <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
          <AssetDimension
            :id="dimension.id"
            v-for="(dimension, index) in dimensionsMapped"
            :key="dimension.id"
            :dimension="dimension"
            :index="index"
            :selectedWidget="selectedWidget"
            @removeDimension="$emit('removeDimension', dimension)"
            @toggleTargetValueEnabled="$emit('toggleTargetValueEnabled', dimension)"
            @updateTargetValue="$emit('updateTargetValue', dimension, $event)"
            @updateChartType="$emit('updateChartType', dimension, $event)"
          />
        </transition-group>
      </draggable>
    </MainStyled>
  </DimensionsSelectedStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import AssetDimension from './AssetDimension'

import draggable from 'vuedraggable'

import { flexCenter } from '@styles/mixins'

const DimensionsSelectedStyled = styled('div')`
  grid-area: dimensions-selected;
  margin: 0.5rem;
  display: grid;
  grid-template-rows: 2rem 1fr;
  grid-template-columns: 1fr;
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  border-radius: 0.5rem;
  grid-auto-rows: min-content;
  overflow-y: auto;
`

const HeaderStyled = styled('div')`
  ${flexCenter}
  color: ${p => p.theme.colors.primary};
  border-bottom: 1px solid ${p => (p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.lightGrey)};
`

const MainStyled = styled('div')`
  overflow: auto;
  padding: 0.5rem; 
}
`

export default {
  order: 0,
  name: 'simple',
  display: 'Simple',
  data() {
    return {
      enabled: true,
      dragging: false,
      index: 0,
    }
  },
  props: {
    dimensionsSelected: {
      type: Array,
      required: true,
    },
    widgetDimensions: {
      type: Array,
      required: true,
    },
    selectedWidget: {
      type: Object,
    },
  },
  components: {
    DimensionsSelectedStyled,
    AssetDimension,
    HeaderStyled,
    MainStyled,
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    dimensionsMapped() {
      return this.dimensionsSelected.map(dim => ({
        ...dim,
        isSelected: true,
        widgetDimension: this.widgetDimensions.find(wd => wd.assetDimension.id === dim.id),
        isConfigurable: true,
      }))
    },
  },
}
</script>
